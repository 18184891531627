<template>
  <div :class="rootClasses">
    <HeaderControllerSkeleton />
    <FeedControllerSkeleton />
    <main class="footer-pages-layout__main">
      <div class="footer-pages-layout__main_gradient" />
      <div class="footer-pages-layout__main_page">
        <slot />
      </div>
    </main>
    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const rootClasses = computed(() => ({
  'footer-pages-layout': true,
  [`footer-pages-layout--${route.meta.layoutClass}`]: true,
}));
</script>

<style lang="scss" scoped src="assets/layouts/footer-pages.scss"></style>
